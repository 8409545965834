// import "./styles.css";
import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  AreaChart,
  Area,
} from "recharts";

// const data = [

// //   {
// //     name: "Page A",
// //     uv: 4000,
// //     pv: 200,
// //     amt: 20,
// //   },
// ];

export default function PeakScreenTime(data2) {
  // console.log(data2?.data2);
  return (
    <div>
      <h4>Top Apps Screen Time</h4>
      <ResponsiveContainer width="95%" height={300}>
        <LineChart
          data={data2?.data2}
          syncId="anyId"
          margin={{
            top: 10,
            right: 5,
            left: 0,
            bottom: 0,
          }}
          barSize={50}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {/* <YAxis /> */}
          <Tooltip />
          <Line
            type="monotone"
            dataKey="screenTime"
            stroke="#e9a1d1"
            fill="#82ca9d"
          />
          {/* <Brush /> */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
