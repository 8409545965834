import React from "react";

const UReferrals = (refUser) => {
  // const haaa = ref;
  // console.log(refUser?.data?.ref);
  // partner_email email
  return (
    <div className="row profile-body">
      <div className="col-md-8 col-xl-18 mx-auto middle-wrapper post-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card rounded">
              <div className="card-header">
                <h5 className="my-3"> {refUser?.data?.name} Referrals</h5>

                {refUser?.data?.ref.length === 0 ? (
                  <p>No Ref Yet</p>
                ) : (
                  refUser?.data?.ref?.map((item, index) => {
                    // console.log(item);
                    return (
                      <div
                        key={index}
                        style={{
                          boxShadow: "2px 4px 10px 1px rgba(0,0, 0,.3)",
                          padding: "1rem",
                          margin: "0.8rem 0",
                        }}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div
                          // style={{ minWidth: "100%" }}
                          className="d-flex align-items-center"
                        >
                          <div
                            style={{
                              minWidth: "100%",
                              justifyContent: "space-around",
                              gap: "2.5rem",
                            }}
                            className="ml-2 d-flex"
                          >
                            <div>
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "700",
                                  padding: "0px",
                                  margin: "0px",
                                }}
                                className=""
                              >
                                Name: {item?.user?.name}
                              </p>
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "700",
                                  padding: "0px",
                                  margin: "0px",
                                }}
                                className=""
                              >
                                Email: {item?.user?.email}
                              </p>{" "}
                            </div>
                            ||
                            <p
                              style={{
                                fontSize: "1rem",
                                fontWeight: "700",
                                padding: "0px",
                                margin: "0px",
                              }}
                              className=""
                            >
                              Partner's Email: {item?.user?.partner_email}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UReferrals;
