import React from "react";

export default function Footer() {
  return (
    <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between">
      <p className="text-muted text-center text-md-left">
        Copyright © 2022{" "}
        <a href="#" target="_blank">
          Applatch
        </a>
        . All rights reserved
      </p>
    </footer>
  );
}
