import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PhoneUsageAnalyses.css";
import moment from "moment";
import PeakScreenTime from "./Charts/PeakScreenTime";
import TodaysFocusTime from "./Charts/TodaysFocusTime";
import UserAverageScreenTime from "./Charts/UserAverageScreenTime";
const SCREEN_TIME = "https://backend.applatch.com/api/v1/screen/admin/date";
const SCREEN_APP = "https://backend.applatch.com/api/v1/screenapp/admin/date";

export default function PhoneUsageAnalyses({ userId }) {
  const [userScreen, setUserScreen] = useState({});
  const [userScreenApp, setUserScreenApp] = useState({});
  const [date, setDate] = useState(moment().format("l"));
  const [errMsg, setErrMsg] = useState("");
  const [screenTime, setScreenTime] = useState([]);
  const handleChange = (e) => {
    setDate(e.target.value);
  };

  // console.log(userScreenApp?.appListScreenTime);

  const handleSubmit = async (e) => {
    // e.preventDefault();

    try {
      const response = await axios.post(
        SCREEN_TIME,
        { id: userId, date: moment(date).format("l") },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
          },
        }
      );
      const { data } = response?.data;
      setScreenTime(
        Object.entries(data?.focusTime).map((item, index) => {
          return {
            time: item[0],
            minutes: item[1],
          };
        })
      );
      //   console.log(response.message);
      setUserScreen(data);
      // console.log(data);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      }
    }
  };
  const handleSubmit1 = async (e) => {
    // e.preventDefault();
    try {
      const response = await axios.post(
        SCREEN_APP,
        { id: userId, date: moment(date).format("l") },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
          },
        }
      );
      const { data } = response?.data;
      //   console.log(response.message);
      setUserScreenApp(data);
      // console.log(response?.data?.success && "yes");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      }
    }
  };

  useEffect(() => {
    handleSubmit();
    handleSubmit1();
  }, [date]);

  const weeklyScreen = userScreen?.weeklyAverageScreenTime?.map((item) => {
    // return console.log(item);
    const h1 = item?.h < 60 ? item?.h : Math.floor(item?.h / 60);

    return {
      name: item?.day,
      hours: h1,
    };
  });

  const topAppTime = userScreenApp?.appListScreenTime?.map((item) => {
    return {
      name: item.name,
      screenTime: item.screenTime,
    };
  });

  // console.log(topAppTime);
  return (
    <div className="row profile-body">
      <div className="col-md-8 col-xl-6 mx-auto middle-wrapper post-wrapper">
        <div
          style={{
            boxShadow: "2px 4px 10px 1px rgba(0,0, 0,.3)",
            maxWidth: "30%",
            margin: "0px auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="my-5 py-2"
        >
          {/* <label htmlFor="DATA">hhheheheh</label> */}
          <input
            style={{ visibility: "" }}
            id="DATA"
            type="date"
            name="date"
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card rounded">
              <div className="card-header">
                {/* <div className="d-flex align-items-center justify-content-between"> */}
                <UserAverageScreenTime data1={weeklyScreen} />
                {/* </div> */}
                {/* <PeakScreenTime /> */}
              </div>
            </div>

            <div className="card rounded mt-3">
              <div className="card-header">
                {/* <div className="d-flex align-items-center justify-content-between">
                  <UserAverageScreenTime />
                </div> */}
                <PeakScreenTime data2={topAppTime} />
              </div>
            </div>
            <div className="card rounded mt-3">
              <div className="card-header">
                {/* <div className="d-flex align-items-center justify-content-between">
                  <UserAverageScreenTime />
                </div> */}
                <TodaysFocusTime data={screenTime} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
