import React, { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import ActiveUser from "./Charts/ActiveUser";

const ActiveUsersChart = () => {
  const { activeUsers, stat } = useContext(AuthContext);

  return (
    <div className="my-3 mx-1">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start mb-2">
            <h6 className="card-title mb-0">
              Active Users In The Pass 15 Days
            </h6>
          </div>
          <div className="monthly-sales-chart-wrapper">
            <ActiveUser data={activeUsers} />
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-around">
        <div
          className="card mt-4 mx-2"
          style={{
            height: "150px",
            width: "400px",
            boxShadow: "1rem 0.3rem 0.9rem #e3e3e3",
          }}
        >
          <div className="card-body">
            <div
              style={{ flexDirection: "column" }}
              className="d-flex align-items-center mb-2"
            >
              <h6 className="card-title mb-0">Monthly Active Users</h6>
              {/* <h3 className="mb-4">{totalUsers}</h3> */}
              <p
                style={{
                  marginTop: "1rem",
                  fontWeight: "800",
                  fontSize: "3rem",
                }}
              >
                {stat.mau}
              </p>
            </div>
          </div>
        </div>
        <div
          className="card mt-4 mx-2"
          style={{
            height: "150px",
            width: "400px",
            boxShadow: "1rem 0.3rem 0.9rem #e3e3e3",
          }}
        >
          <div className="card-body">
            <div
              style={{ flexDirection: "column" }}
              className="d-flex align-items-center mb-2"
            >
              <h6 className="card-title mb-0">Weekly Active Users</h6>
              {/* <h3 className="mb-4">{totalUsers}</h3> */}
              <p
                style={{
                  marginTop: "1rem",
                  fontWeight: "800",
                  fontSize: "3rem",
                }}
              >
                {stat.wau}
              </p>
            </div>
          </div>
        </div>
        <div
          className="card mt-4 mx-2"
          style={{
            height: "150px",
            width: "400px",
            boxShadow: "1rem 0.3rem 0.9rem #e3e3e3",
          }}
        >
          <div className="card-body">
            <div
              style={{ flexDirection: "column" }}
              className="d-flex align-items-center mb-2"
            >
              <h6 className="card-title mb-0">Daily Active Users</h6>
              {/* <h3 className="mb-4">{totalUsers}</h3> */}
              <p
                style={{
                  marginTop: "1rem",
                  fontWeight: "800",
                  fontSize: "3rem",
                }}
              >
                {stat.dau}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveUsersChart;
