import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./styles/PaymentEmail.css";
import AuthContext from "../context/AuthProvider";

import axios from "../API/axios";
const PAYMENT_URL = "/payment";
const USER_DETAILS_ID = "/user";

//
const PaymentEmail = () => {
  const { setSucces } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const { emailId } = useParams();
  const [values, setValues] = useState({
    // email: email ? email?.email : "",
    email: email?.email,
    amount: "",
  });
  const [errMsg, setErrMsg] = useState("");
  //   console.log(emailId);
  // console.log(values);
  const fetchUserByEmailId = async () => {
    try {
      const response = await axios.get(`${USER_DETAILS_ID}/${emailId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      //   console.log(response?.data);
      const { data } = response?.data;
      setEmail(data);
      // setSucces((prev) => !prev);
    } catch (err) {
      //   console.log(err.response);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { amount } = values;
    try {
      const response = await axios.post(
        PAYMENT_URL,
        { email: email?.email, amount },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
          },
        }
      );
      //   console.log(response.message);
      setErrMsg(response?.data.message);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      }
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  //   console.log(values);
  //   console.log(errMsg);

  useEffect(() => {
    fetchUserByEmailId();
  }, [emailId]);

  return (
    <div className="p-5">
      <Link to={"/topReferrals"} className="emailBack">
        &#8656; Go Back
      </Link>
      <h2 className="mt-4">
        Make Payment To,{" "}
        <em style={{ color: "blue", fontSize: "1.5rem" }}>{email?.email}</em>
      </h2>
      <form
        onSubmit={handleSubmit}
        style={{
          flexDirection: "column",
          backgroundColor: "transparent",
          //   gap: "0.5rem",
          width: "400px",
        }}
        className="d-flex my-4 coolForm"
      >
        <input
          type="text"
          name="email"
          value={email?.email}
          id=""
          onChange={onChange}
          placeholder="Type In The Email"
        />
        <br />
        <input
          type="text"
          name="amount"
          placeholder="Type The Amount Here"
          onChange={onChange}
        />
        <button className="btn btn-primary paymentBtn">Send</button>
        <p style={{ color: "#98bb0e", fontSize: "1.5rem" }}>{errMsg}</p>
      </form>
    </div>
  );
};

export default PaymentEmail;
