import { createContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "../API/axios";
const USERS_URL = "/users";
const USERS_ACCOUNTABILITY_COUNT_URL = "/users/accountabilityCount";
const DAILY_LOCK_COUNT_URL = "/dailyLockCount";
const SCHEDULE_LOCK_COUNT_URL = "/scheduleLockCount";
const TOTAL_LOCK_COUNT_URL = "/totalLockCount";
const NEW_USERS_URL = "/users/stats";
const MONTHLY_USERS_URL = "/users/month";

const AuthContext = createContext({});

// const USERS_URL = "/users";

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [newUsers, setNewUsers] = useState([]);
  const [topReferrals, setTopReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataMain, setDataMain] = useState([]);
  const [users, setUsers] = useState([]);  
  const [usersPage, setUsersPage] = useState(1);
  const [topReferralsPage, setTopReferralsPage] = useState(1);
  const [activeUsers, setActiveUsers] = useState([]);
  const [stat, setStat] = useState([]);
  const [succes, setSucces] = useState(false);
  const [usersAccountabilityPartnerCount, setUsersAccountabilityPartnerCount] = useState([]);
  const [dailyLockCount, setDailyLockCount] = useState([]);
  const [scheduleLockCount, setScheduleLockCount] = useState([]);
  const [totalLockCount, setTotalLockCount] = useState([]);

  const totalUsersWithAccountabilityPartners = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${USERS_ACCOUNTABILITY_COUNT_URL}`, {        
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      const { data } = response?.data;
      setUsersAccountabilityPartnerCount(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
    }
  };

  const getDailyLockCount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${DAILY_LOCK_COUNT_URL}`, {        
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      const { data } = response?.data;
      setDailyLockCount(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
    }
  };

  const getScheduleLockCount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${SCHEDULE_LOCK_COUNT_URL}`, {        
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      const { data } = response?.data;
      setScheduleLockCount(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
    }
  };

  const getTotalLockCount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${TOTAL_LOCK_COUNT_URL}`, {        
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      const { data } = response?.data;
      setTotalLockCount(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
    }
  };

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${USERS_URL}?page=${usersPage}`, {
        // headers: { "x-access-token": localStorage.getItem("token") },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      const { data } = response?.data;
      setUsers(data);
      // console.log(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
    }
  };

  const fetchNewUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${NEW_USERS_URL}?page=${topReferralsPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
          },
        }
      );
      const { data } = response?.data;
      setStat(data);
      setNewUsers(data?.newUsers);
      setTopReferrals(data?.topReferrals);
      setActiveUsers(
        Object.entries(data?.activeUsers).map((item) => {
          const date = new Date(item[0]);
          const month = date.getMonth();
          const day = date.getDate();
          const monthName = date.toLocaleString("default", {
            month: "short",
          });
          // object.entries returns arrays of array, so at each item position you return the index u want
          return {
            // name: Day + "/" +  (month + 1),
            name: monthName + "/" + day,
            amt: item[1],
          };
        })
      );
      setIsLoading(false);
      console.log(data);
    } catch (err) {
      console.log(err.response);
    }
  };

  console.log(users?.length);

  const fetchUsersMonthly = async () => {
    try {
      const response = await axios.get(MONTHLY_USERS_URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      // console.log(response.data);
      const { data } = response?.data;
      const data1 = Object.entries(data).map((item) => {
        // object.entries returns arrays of array, so at each item position you return the index u want
        return {
          name: item[0].slice(0, 3),
          amt: item[1],
        };
      });

      setDataMain(data1);
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [usersPage]);

  useEffect(() => {
    fetchNewUsers();
  }, [topReferralsPage]);
  useEffect(() => {
    fetchUsersMonthly();
  }, []);
  useEffect(() => {
    totalUsersWithAccountabilityPartners();
  }, []);
  useEffect(() => {
    getDailyLockCount();
  }, []);
  useEffect(() => {
    getScheduleLockCount();
  }, []);
  useEffect(() => {
    getTotalLockCount();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        stat,
        auth,
        setAuth,
        topReferrals,
        isLoading,
        newUsers,
        dataMain,
        users,
        setUsersPage,
        usersPage,
        topReferralsPage,
        setTopReferralsPage,
        activeUsers,
        setSucces,
        usersAccountabilityPartnerCount,
        dailyLockCount, 
        scheduleLockCount, 
        totalLockCount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
