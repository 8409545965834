import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },

  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
];

export default function UserAverageScreenTime(data1) {
  // console.log(data1?.data1);
  return (
    <>
      <div>
        <h3>Weekly Average Screen Time</h3>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data1?.data1}
          margin={{
            top: 0,
            right: 5,
            left: 5,
            bottom: 0,
          }}
        >
          <XAxis dataKey="name" padding={{ left: 15, right: 13 }} />
          {/* <YAxis /> */}
          <Tooltip style={{ background: "rgba(0,0,0,0.4)" }} />
          {/* <Legend /> */}
          <CartesianGrid strokeDasharray="1" />
          <Bar dataKey="hours" fill="#8ebdff" background={{ fill: "#eee" }} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
